import { getEchars } from "@/api/index/index";
export default {
  data: () => {
    return {
      rightarr: "",
      ydata: [],
      projactNanme: "热风炉",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(date) {
          // 当天可选：date.getTime() < Date.now() - 24 * 60 * 60 * 1000
          return date.getTime() > Date.now();
        },
      },
    };
  },
  watch: {},
  created() {
    this.historyname = this.$route.query.Firstcontrolname;
    this.titname = this.$route.query.Firstcontroltitname;
    this.node = this.$route.query.Firstcontrolnode;
    let projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.projactNanme = projectData.project;
    this.projectData = projectData;
    // this.jsostr = this.historyname + 'SEL'
    // this.qcty = this.infoList.G_MCSOTHER[this.jsostr]
    this.mark = ["SP", "PV", "AV"];
    this.nodeList = [this.node, this.node, this.node];
    let charAtlet = "";
    if (projectData.type == "rfl") charAtlet = "_WF";
    if (projectData.type == "jrl") charAtlet = "_J1";
    if (projectData.type == "rql") charAtlet = "_NG3";
    if (projectData.type == "cfb") charAtlet = "VV1";
    // if(projectData.type == 'mfl') charAtlet = '_BM'
    if (projectData.type == "mfl") {
      charAtlet = "_BM";
      this.node = this.$route.query.Firstcontrolname;
      this.nodeList = [
        this.$route.query.Firstcontrolnode,
        this.$route.query.Firstcontrolnode,
        this.$route.query.Firstcontrolnode,
      ];
    }
    this.charAtlet = charAtlet;
    this.name = [
      this.historyname + "_SP" + charAtlet,
      this.historyname + "_PV" + charAtlet,
      this.historyname + "_AV" + charAtlet,
    ];

    this.echartcolor = ["#1799cd", "#0de0ac", "#ec163e"];
    this.ydata = [
      {
        type: "value",
        name: "",
        position: "left",
        boundaryGap: [0, "100%"],
        scale: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[0],
          },
        },
        axisTick: {
          show: true,
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "left",
        offset: 50,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[1],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "left",
        offset: 100,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[2],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "left",
        offset: 150,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[3],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "right",
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[4],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "right",
        offset: 50,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[5],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "right",
        offset: 100,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[6],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        position: "right",
        offset: 150,
        scale: true,
        axisTick: {
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[7],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
      {
        type: "value",
        name: "",
        offset: 200,
        scale: true,
        axisTick: {
          show: true,
        },
        position: "right",
        axisLine: {
          show: true,
          lineStyle: {
            color: this.echartcolor[8],
          },
        },
        axisLabel: {
          formatter: "{value}",
        },
      },
    ];
  },
  mounted() {},
  computed: {},
  methods: {
    // 打开对应历史趋势
    tocomment(name, color, key) {
      this.echarShow[key] = !this.echarShow[key]; //点击对应变量取反操作
      let charAtlet = "";
      if (this.projectData.type == "rfl") charAtlet = "_WF";
      if (this.projectData.type == "jrl") charAtlet = "_J1";
      if (this.projectData.type == "rql") charAtlet = "_NG3";
      if (this.projectData.type == "cfb") charAtlet = "VV1";
      if (this.projectData.type == "mfl") charAtlet = "_BM";
      if (!this.echarShow[key]) {
        if (this.mark.length == 1) {
          this.echarShow[key] = true;
          return this.$message.error("至少保留一条曲线");
        }
        // 当点击对应变量为不展示时
        this.mark = this.mark.filter((item) => item != name); //过滤点击变量，返回其余变量
        this.name = this.name.filter(
          (item) => item != this.historyname + "_" + name + charAtlet
        );
        this.nodeList.length = this.mark.length;
        this.echartcolor = this.echartcolor.filter((item) => item != color);
      } else {
        // 当点击对应变量为展示时
        this.mark.splice(key, 0, name); //向指定位置添加元素
        this.name.splice(key, 0, this.historyname + "_" + name + charAtlet);
        this.nodeList.splice(key, 0, this.node);
        this.echartcolor.splice(key, 0, color);
      }
      this.loading = true;
      this.getInfo();
    },
    // 获取折线图数据
    getInfo() {
      // this.echartData = [];
      // this.xAxis = [];
      let params;
      if (this.start_time) {
        params = {
          device: this.projectData.device,
          mark: this.mark,
          name: this.name,
          node: this.nodeList,
          end_time: this.end_time,
          start_time: this.start_time,
          cp_type: this.projectData.type,
        };
      } else {
        params = {
          device: this.projectData.device,
          mark: this.mark,
          name: this.name,
          node: this.nodeList,
          cp_type: this.projectData.type,
        };
      }
      getEchars(params)
        .then((res) => {
          let hisdata = {
            dataZoom: {},
            dataView: {
              readOnly: true,
              optionToContent: function (option) {
                var axisData = option.xAxis[0].data;
                var series = option.series;
                var tdHeaders =
                  '<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">时间</td>'; //表头
                series.forEach(function (series) {
                  tdHeaders +=
                    '<td style="border:1px solid #ccc;border-bottom:0;border-right:0;padding:0 10px;background: #f5f7fa;">' +
                    series.name +
                    "</td>"; //组装表头
                });
                var table =
                  '<div class="addtable"><table border="0" style="text-align:center;margin:10px;border-right:1px solid #ccc;border-bottom:1px solid #ccc;" cellspacing="0" cellpadding="0"><tbody><tr>' +
                  tdHeaders +
                  "</tr>";
                var tdBodys = ""; //数据
                for (var i = 0, l = axisData.length; i < l; i++) {
                  for (var j = 0; j < series.length; j++) {
                    tdBodys +=
                      '<td style="border:1px solid #ccc;border-bottom:0;border-right:0"> ' +
                      series[j].data[i] +
                      "</td>"; //组装表数据
                  }
                  table +=
                    '<tr ><td style="border:1px solid #ccc;border-bottom:0;border-right:0"> <div   class="addar" style="width:100px;height:40px;line-height:40px">' +
                    axisData[i] +
                    "</div></td>" +
                    tdBodys +
                    "</tr>";
                  tdBodys = "";
                }
                table += "</tbody></table></div>";
                return table;
              },
            },

            restore: {},
            saveAsImage: {},
          };
          this.loading = false;
          this.xAxis = res.data.x;
          this.echartData = res.data.series;
          this.option = {
            title: {
              text: "",
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
              appendToBody: "html",
              className: "echarts-tooltip echarts-tooltip-dark",
              formatter: (params) => {
                this.rightarr = params;
              },
            },
            color: this.echartcolor,
            legend: {
              data: this.name,
            },
            grid: {
              left: "10%",
              right: "13%",
              bottom: "10%",
              containLabel: true,
            },
            toolbox: {
              feature: this.active == "10" ? {} : hisdata,
              top: 20,
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                onZero: false,
                lineStyle: {},
              },

              data: this.xAxis,
            },
            yAxis: this.ydata,
            series: this.echartData,
            dataZoom: [
              {
                //默认为滚动条型数据区域缩放组件
                show: true,
                xAxisIndex: [0],
                start: 100,
                end: 0,
                // 开始位置的数值
                startValue: 100,
                // 结束位置的数值
                endValue: 0,
              },
            ],
          };
        })
        .catch((err) => {
          this.getInfo();
          this.loading = false;
          // this.$message.error('数据获取失败');
        });
    },
    // 曲线图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("rts_Chart"),
        "dark"
      );
      this.myChart = myChart;
      // 绘制图表
      this.myChart.setOption(this.option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.myChart.on("click", function (params) {
        console.log(this.rightarr);
      });
    },
  },
};
